
$image-ratio: (
    'logo': (w: 91, h: 71),
    '1x1': (w: 1, h: 1),
    '3x2': (w: 3, h: 2),
    '4x3': (w: 4, h: 3),
    '3x4': (w: 3, h: 4),
    '16x9': (w: 16, h: 9),
    '166x75': (w: 166, h: 75),
    '167x76': (w: 167, h: 76),
    '288x345': (w: 288, h: 345),
    '1366x768': (w: 1366, h: 768),
    '342x144': (w: 342, h: 144),
    '288x312': (w: 288, h: 312),
    '46x37': (w: 46, h: 37),
    'popupVideo': (w: 8017, h: 4509),
    '3543x2835': (w: 3543, h: 2835),
    '2480x1063': (w: 2480, h: 1063),
    '1000x2165': (w: 1000, h: 2165),
    '375x315': (w: 375, h: 315),
    '375x93': (w: 375, h: 93),
    '1600x1377': (w: 1600, h: 1377),
    '1068x1144': (w: 1068, h: 1144),
    '800x870': (w: 800, h: 870),
    '518x175': (w: 518, h: 175),
);


.a-image {
    display: block;
    overflow: hidden;
    padding-bottom: 100%;
    position: relative;
    transition: $transition-long ease;
    width: 100%;

    img {
        height: 100%;
        left: 0;
        object-fit: cover;
        position: absolute;
        top: 0;
        width: 100%;
    }

    @each $size,
        $val in $image-ratio {
        &-#{$size} {
            @include aspect-ratio(map-get($val, 'w'), map-get($val, 'h'));
        }
    }
}
