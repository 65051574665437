.t-banner {
    $root: &;
    position: relative;

    &-isCenter {
        #{$root}_wrapper {
            @include adjust-flex();
        }
    }

    &_background {
        .a-image {
            @include tablet-up {
                min-height: rem(720);
            }

            @include tablet-down {
                @include aspect-ratio(375, 812);
                // padding-bottom: 141.19321%;
            }
        }
    }

    &-isHorizontal {
        #{$root}_wrapper {
            padding: rem(24) rem(16);
        }

        #{$root}_relative {
            height: 100%;
            overflow-y: auto;
            @include mobile-up {
                @include adjust-flex();
                flex-direction: column;
            }
        }

        #{$root}_background {
            .a-image {
                padding-bottom: 63%;
            }
        }

        #{$root}_heading {
            max-width: 70%;
        }

        .t-bannerHome {
            &_logo {
                margin: 0 auto rem(8);

                &_item {

                    width: rem(76);
                    height: rem(35);

                    &:first-child {
                        width: rem(35);
                        height: rem(35);
                    }
                }
            }

            &_datetime {
                @include tablet-down {
                    width: 85%;
                }

                .a-text {
                    @include font-base(16, 24);
                }
            }

            &_countdown {
                margin-top: rem(8);

                &_title {
                    .a-text {
                        @include font-base(16, 24);
                    }
                }

                .m-countdown {
                    margin-top: rem(4);

                    &-time {
                        @include font-base(24, 33);
                    }

                    &-timeleft {
                        height: rem(56);
                        width: rem(56);

                        &:not(:last-child) {
                            margin-right: rem(16);
                        }
                    }

                    &-unit {
                        @include font-base(12, 20);
                    }
                }
            }

            &_button {
                max-width: rem(220);

                .a-button {
                    height: rem(36);
                }
            }
        }
    }


    &:not(.t-banner-isHorizontal) {
        #{$root}_wrapper {
            @include tablet-down {
                width: 100%;
                min-width: inherit;
                top: 0;
                padding: rem(12) 0 rem(12);
                height: 100%;
            }
        }
    }

    &_relative {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    &_wrapper {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        transition: $transition ease-in;
        padding-bottom: rem(12);
    }

    &_logo {
        width: 100%;
        padding: rem(24) rem(60) rem(20);
        @include adjust-flex(space-between, center);
        @include mobile-down {
            padding: 0 rem(16);
        }

        &_item {
            width: rem(114);

            @include break-min($standard-viewport) {
                width: 114 * $w;
            }

            @include mobile-down {
                width: 100%;
                max-width: rem(60);
            }

            &:last-child {
                width: rem(106);
                @include break-min($standard-viewport) {
                    width: 106 * $w;
                }
            }
        }
    }

    &_heading {
        width: 100%;
        max-width: 80%;
        margin: 0 auto;

        @include break-min($standard-viewport) {
            max-width: 440 * $w;
        }

        @include mobile-down {
            min-width: rem(273);
            max-width: rem(330);
        }

        img {
            width: 100%;
        }
    }

    &_link {
        margin-top: rem(16);

        @include mobile-down {
            margin-top: rem(12);
        }
    }

    &_contentCustom {
        // left: 4%;
        // max-width: rem(428);
        // position: absolute;
        // top: 21%;
        // width: 100%;

        // @include break-min($standard-viewport) {
        //     left: 7%;
        //     max-width: 428 * $w;
        // }

        // @include tablet-down {
        //     left: 50%;
        //     max-width: rem(603);
        //     top: 52%;
        //     transform: translateX(-50%);
        // }

        // @include mobile-down {
        //     max-width: rem(315);
        // }

        // img {
        //     width: 100%;
        // }
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        transition: $transition ease-in;
        padding-bottom: rem(12);
    }
}

.t-bannerHome {
    height: 100%;
    position: relative;

    &_wrapContent {
        width: 100%;
        margin: rem(24) auto 0;

        @include tablet-down {
            margin: rem(8) auto 0;
        }

        @include mobile-down {
            max-width: 80%;
        }
    }

    &_decor {
        .a-image {
            @include tablet-up {
                min-height: rem(720);
            }

            @include tablet-down {
                @include aspect-ratio(375, 812);
                // padding-bottom: 141.19321%;
            }
        }
    }

    &_content {
        width: 60%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: auto;
        height: 100%;

        @include tablet-down {
            width: 100%;
            justify-content: flex-start;
            padding: rem(16);
        }

        .a-text-20x24 {
            @include break-min($standard-viewport) {
                font-size: 20 * $w;
                line-height: 24 * $w;
            }
        }
    }

    &_image {
        width: calc(100% + 32px);
        margin-left: rem(-16);
        margin-right: auto;
        margin-bottom: rem(8);
        @include tablet-up {
            position: absolute;
            top: 0;
            width: 50%;
            left: 0;
            margin-bottom: 0;
        }
    }

    &_logo {
        width: 100%;
        margin-bottom: rem(48);
        @include adjust-flex(center, center);
        @include mobile-down {
            margin-bottom: rem(24);
            padding: 0 rem(16);
        }

        &_item {
            width: rem(114);
            margin-left: rem(12);
            margin-right: rem(12);

            @include break-min($standard-viewport) {
                width: 114 * $w;
            }

            @include mobile-down {
                width: 100%;
                max-width: rem(60);
            }

            &:last-child {
                width: rem(106);
                @include break-min($standard-viewport) {
                    width: 106 * $w;
                }
            }
        }
    }


    &_countdown {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: rem(6);

        &_title {
            .a-text-24x36 {
                @include mobile-down {
                    @include font-base(14, 21);
                }
            }
        }

        @include desktop {
            margin-top: rem(14);
        }

        .m-countdown {
            margin-top: rem(16);

            @include break-min($standard-viewport) {
                margin-top: 16 * $w;
            }

            @include mobile-down {
                margin-top: rem(10);
            }
        }
    }

    &_datetime {
        width: 100%;
        text-align: center;
        margin-top: rem(24);

        @include desktop-down {
            white-space: nowrap;
        }

        @include tablet-down {
            margin-top: rem(8);
        }

        p:first-child {
            @include mobile-down {
                margin-left: rem(6);
                margin-right: rem(6);
            }

            @media (max-width: 374px) {
                margin: 0;
            }
        }

        .a-text {
            @include mobile-down {
                font-size: rem(12);
                line-height: rem(16);
            }
        }

        &_line {
            width: rem(60);
            height: rem(4);
            margin: rem(8) auto;
            background: linear-gradient(90deg, rgba(175, 175, 175, 0) 0%, rgba(224, 224, 224, 0.7) 12.5%, #afafaf 49.48%, rgba(224, 224, 224, 0.7) 86.46%, rgba(175, 175, 175, 0) 100%);
            @include mobile-down {
                width: rem(48);
                height: rem(2);
                margin-top: rem(4);
                margin-bottom: rem(4);
            }
            @media (max-width: 374px) {
                display: none;
            }
        }

        @media (max-width: 374px) {
            flex-direction: column;
        }
    }

    &_button {
        margin: rem(16) auto 0;
        max-width: rem(243);

        .a-button {
            border-radius: 12px;
            border: 2px solid var(--dkngaystroke, #ecbc79);
            background: var(--DKNGAY, linear-gradient(87deg, #ecbc79 0.57%, #bc8b42 7.36%, #edd79d 25.89%, #fff3bf 51%, #ecd69c 75.3%, #bc8b42 88.26%, #ecbc79 98.19%));

            &_wrapper {
                background: none;
            }
        }

        @include break-min($standard-viewport) {
            max-width: 243 * $w;
            margin-top: 16*$w;

            .a-button {
                font-size: 24 * $w;
                height: 56 * $w;
                line-height: 32 * $w;
            }
        }

        @include mobile-down {
            margin-top: rem(8);
            width: rem(190);

            .a-button_wrapper {
                line-height: rem(30);
                margin-top: -2px;
                font-size: rem(20);
            }
        }
    }

    &_title {
        margin-bottom: rem(32);
        @include mobile-down {
            margin-top: rem(16);
        }
        @media (max-width: 376px) {
            .a-text {
                @include font-base(14, 20); }
        }
    }

    &_sologan {
        margin-top: rem(-12);

        &.section {
            margin-top: rem(6);
        }

        @include mobile-down {
            margin-top: rem(0);
            padding: 0 rem(8);

            .a-text {
                @include font-base(16, 24); }
        }

        @media (max-width: 376px) {
            padding: 0 rem(16);

            .a-text {
                @include font-base(14, 20); }
        }
    }

    &_steps {
        display: flex;
        width: 100%;
        flex-grow: 1;
        padding-left: 7%;
        padding-right: 7%;
        margin-top: rem(4);
        align-items: center;
        justify-content: space-between;
        background: url('~assets/images/new/home/banner-decorator.png') no-repeat;
        background-size: cover;

        @include mobile-down {
            padding: 0;
            flex-direction: column;
            align-items: flex-start;
            background: none;
        }

        &-notFit {
            flex-grow: 0;
        }
    }

    &_step {
        width: 24%;
        @include mobile-down {
            width: 61.87%;

            &:not(:first-child) {
                margin-top: -15%;
            }

            &:nth-child(2) {
                align-self: flex-end;
            }
        }

        @include small-mobile {
            width: 56%;
        }
    }
}
