.o-popup {
    $root: &;
    border: 0;
    border-radius: rem(8);
    color: $white;
    max-height: rem(640);
    // max-width: rem(797);
    outline: 0;
    overflow: auto;
    position: fixed;
    width: 100%;
    @include scroll-bars(8, $dim-gray);

    @include tablet-down {
        max-height: 90%;
    }

    &_heading {
        margin-bottom: rem(24);

        @include mobile-down {
            margin-bottom: rem(16);
        }
    }

    &-register {
        max-width: rem(1020);
    }

    // &-fGameThanks {
    //   @include desktop-up {
    //     max-width: 1116px;
    //   }

    //   #{$root}_body {
    //     background: $white url('~assets/images/bg-foyer-popup.png') no-repeat center/123% auto;
    //   }

    //   #{$root}_notice {
    //     .a-text-24x32 {
    //       @include tablet-down {
    //         @include font-base(14, 19);
    //       }
    //     }
    //   }

    //   .a-text-16x24 {
    //     @include tablet-down {
    //       @include font-base(12, 17);
    //     }
    //   }
    // }

    &-endLive,
    &-endFoyer {
        max-width: rem(1116);

        // #{$root}_title {
        //   .a-heading {
        //     @include desktop-up {
        //       color: $dark-slate-blue;
        //       font-weight: 900;
        //       @include font-base(40, 54);
        //     }
        //   }
        // }

        // #{$root}_body {
        //   background: $white url('~assets/images/bg-popup-livestream.png') no-repeat center/100% auto;

        //   @include desktop-up {
        //     padding-bottom: rem(70);
        //     padding-top: rem(70);
        //   }
        // }

        #{$root}_content {
            margin: 0 auto rem(10);
            max-width: 900px;

            @include tablet-down {
                max-width: rem(500);
            }

            .a-text-24x32 {
                @include tablet-down {
                    @include font-base(17, 25);
                }
            }

            .a-text-24x32 {
                @include tablet-down {
                    @include font-base(19, 29);
                }
            }

            &~.a-text-16x24 {
                @include tablet-down {
                    @include font-base(12, 18);
                }
            }
        }
    }

    &-noticeFoyer {
        max-width: 1115px;

        @include tablet-down {
            max-width: 73vw !important;
        }

        #{$root}_body {
            padding: 0;
        }
    }

    &-previewCertificate {
        max-height: 100%;

        @include desktop-up {
            max-width: rem(612) !important;
        }

        #{$root}_body {
            padding: rem(32) rem(16);

            &::before,
            &::after {
                background: none;
            }
        }
    }

    &-otp,
    &-success {
        max-width: rem(580);

        #{$root}_title {
            span {
                @include font-base(24, 40);
            }
        }

        #{$root}_heading {
            margin-bottom: rem(4);
        }

        #{$root}_body {
            padding: rem(41) rem(48) rem(29);

            @include tablet-down {
                padding-left: rem(24);
                padding-right: rem(24);
            }
        }
    }

    &-success {
        max-width: rem(600);
        
        #{$root}_body {
            padding: rem(47) rem(31);
        }

        #{$root}_button {
            max-width: rem(214);
        }
    }

    &-formLogin {
        @include desktop-up {
            max-width: rem(600) !important;

            #{$root}_body {
                min-height: rem(550);
            }
        }

    }

    &-forgotPassword {
        @include desktop-up {
            max-width: rem(600) !important;

            #{$root}_body {
                min-height: rem(400);
            }
        }
    }

    &-resetPassword {
        @include desktop-up {
            max-width: rem(828) !important;
        }

        #{$root}_button {
            margin: auto;
        }
    }

   

    &-popupBackground {
        #{$root}_heading {
            margin-bottom: rem(12) !important;
        }

        #{$root}_body {
            padding-bottom: rem(64);
            padding-top: rem(64);
            @include tablet-down {
                padding-bottom: rem(48);
                padding-top: rem(48);
            }
            min-height: rem(320);
        }

        .t-backgroundPanel_content {
            @include tablet-down {
                max-width: 100%;
            }
        }

        &.secondary {
            max-width: rem(1190);
            #{$root}_body {
                &::before {
                    height: rem(280);
                }

                @include mobile-down {
                    padding-left: rem(16);
                    padding-right: rem(16);
                }
            }
        }

        &.resultCme {
            max-width: rem(935);
        }

        &.firstSurvey {
            max-width: rem(982);
        }

        &.endSurvey {
            max-width: rem(952);
        }

        &.endlive {
            max-width: rem(1197);
        }

        &.formCertificate {
            max-width: rem(548);
            max-height: 100%;

            #{$root}_body {
                padding-bottom: rem(42);
                padding-top: rem(42);
            }

            #{$root}_heading {
                margin-bottom: rem(24) !important;
            }

            #{$root}_title {
                span {
                    @include font-base(24, 32);
                }
            }
        }

        &.firstCME {
            max-width: rem(1066);
            #{$root}_body {
                max-width: 93%;
                margin: 0 auto;
                min-height: 0;
            }
        }
    }

    &_sub {
        margin-top: 5px;
        position: relative;

        &::before {
            content: '';
            position: absolute;
            top: rem(-4);
            left: 0;
            width: rem(54);
            height: rem(2);
            background: $linear-seal-brown;
        }
    }

    @include mobile-tablet {
        max-width: 80vw;
    }

    @include mobile-down {
        max-width: 90vw;
    }

    &_title {
        @include adjust-flex();
        position: relative;
        text-align: center;
        text-transform: uppercase;

        // &:not(#{$root}-otp) {
        //   padding: rem(10) 0;
        // }


        span {
            @include text-background($linear-seal-brown);
            font-weight: 900;
            position: relative;
            @include font-base(32, 48);

            @include tablet-down {
                @include font-base(20, 32);
            }

            @include mobile-down {
                @include font-base(18, 28);
            }

        }
    }

    &_close {
        cursor: pointer;
        height: rem(14);
        position: absolute;
        right: rem(21);
        top: rem(25);
        width: rem(14);
        z-index: 3;

        &::before {
            background-color: $bronze2;
            content: '';
            height: 2px;
            left: 0;
            position: absolute;
            top: 0;
            transform: rotate(45deg);
            width: 100%;
        }

        &::after {
            background-color: $bronze2;
            content: '';
            height: 2px;
            left: 0;
            position: absolute;
            top: 0;
            transform: rotate(-45deg);
            width: 100%;
        }
    }

    &-container {
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        background: rgba(14, 14, 14, 0.65);
        // border: 1px solid $uni-california-gold;
        overflow: auto;
        position: relative;
        width: 100%;

        &-noScrollContainer {
            overflow: hidden;
        }
    }

    &:focus {
        outline: 0;
    }

    &-overlay {
        animation: none;
        background: rgba($black, 0.3) !important;
        bottom: 0;
        left: 0;
        position: fixed;
        right: 0;
        top: 0;
        z-index: $z-modal;

        &::after {
            backdrop-filter: blur(8px);
            background: rgba($black, 0.74) !important;
            content: '';
            display: block;
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: -1;
        }
    }

    &_wrapcontent {
        position: relative;
        z-index: $z-2;

    }

    &_heading {
        margin-bottom: rem(24);

        @include mobile-down {
            margin-bottom: rem(16);
        }
    }

    &_body {
        background: $white;
        overflow: hidden;
        padding: rem(40);
        position: relative;

        &::before {
            background: transparent url('~assets/images/pop-texture1.png') no-repeat center/100% auto;
            content: '';
            display: block;
            height: rem(264);
            left: 0;
            pointer-events: none;
            position: absolute;
            top: 0;
            transform: rotate(30deg) translate(-25%, -20%);
            min-width: rem(232);
            width: 30%;
            z-index: $z-1;
        }

        &::after {
            background: transparent url('~assets/images/pop-texture2.png') no-repeat center/100% auto;
            content: '';
            display: block;
            height: rem(220);
            pointer-events: none;
            position: absolute;
            right: 0;
            bottom: 0;
            transform: rotate(200deg) translate(-35%, -40%);
            min-width: rem(315);
            width: 40%;
            z-index: $z-1;
        }

        @include small-mobile {
            padding: rem(30);
        }
    }

    &_content {
        #{$root}-otp & {
            margin-top: rem(16);
        }
    }

    &_button {
        margin: 0 auto;
        max-width: rem(230);

        &.mobile-reverse {
            @include mobile-down {
                display: flex;
                flex-direction: column-reverse;
            }

            .a-button {
                &:first-child {
                    @include mobile-down {
                        margin-top: rem(16);
                    }
                }

                &:last-child {
                    margin-top: 0 !important;
                }
            }
        }

        &.flex {
            display: flex;
            justify-content: space-between;
            max-width: 100%;

            .a-button {
                max-width: rem(200);
            }
        }
    }

    &-policy {
        max-width: rem(636);
        max-height: rem(660);

        #{$root}_body {
            padding: rem(59) rem(40);

            @include mobile-down {
                padding: rem(50) rem(20);
            }
        }

        #{$root}_heading {
            margin-bottom: rem(8);
        }

        #{$root}_title {
            text-align: left;

            span {
                @include font-base(18, 28);
                font-weight: 700;
                @include text-background($linear-seal-brown);

                @include tablet-down {
                    @include font-base(15, 22);
                }
            }
        }

        #{$root}_button {
            margin-top: rem(28);
            max-width: 100%;
            width: fit-content;

            .a-button {
                &_wrapper {
                    padding: 0 rem(36);
                    min-height: rem(36);
                }

                @include tablet-down {
                    height: 100%;

                    &_wrapper {
                        padding: 0 rem(16);
                    }
                }
            }
        }

        #{$root}_content {
            max-height: rem(370 - 32);
            overflow: auto;
            padding-right: rem(8);
            @include scroll-bars(8, $dim-gray);
        }

        #{$root}_wrapContent {
            border: 1px solid #b8b8b8;
            border-radius: 5px;
            padding: rem(16);
            max-height: rem(370);
            padding-right: rem(8);
        }

        @media all and (max-width: 991px) and (orientation:landscape) {
            max-height: rem(340);

            #{$root}_content {
                max-height: rem(114);
            }

            #{$root}_body {
                padding-top: rem(40);
                padding-bottom: rem(15);
            }

            #{$root}_button {
                margin-top: rem(12);

                .a-button {
                    @include font-base(16, 24); }
            }
        }
    }
}

.o-modal-portal-open {
    opacity: 1;
}

.reactmodal-body-open,
.reactmodal-html-open {
    overflow: hidden;
}

.ReactModal__Overlay {
    opacity: 0;
    transform: scale(0) translateX(-100px);
    transition: all 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
    opacity: 1;
    transform: scale(1) translateX(-100px);
}

.ReactModal__Overlay--before-close {
    opacity: 0;
    transform: scale(0);
}

.ReactModal__Overlay {
    align-items: center;
    background: rgba($black, 0.3) !important;
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    // transform: translateY(-100%);
    transition: all 500ms ease-in-out;
    z-index: $z-modal;

    &::after {
        background: rgba($black, 0.74) !important;
        content: '';
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: -1;
    }

    &--open {
        overflow: hidden;

        @include tablet-up {
            padding-right: rem(7);
        }
    }

    &--after-open {
        opacity: 1;
        transform: scale(1);
    }

    &--before-close {
        opacity: 0;
        transform: scale(0);
    }
}

.overflow {
    overflow: hidden;
}
