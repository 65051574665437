.m-otpInput {
    $root: &;
    position: relative;

    @include mobile-down {
        margin-bottom: rem(20);
    }

    &_wrapper {
        align-items: center;
        display: flex;
        justify-content: center;
        margin-top: rem(20);
    }

    &.error {
        input {
            color: $rich-black;
            border: 1px solid $ku-crimson;
        }
    }

    .a-input {
        margin-left: rem(8);
        margin-right: rem(8);

        @include tablet-down {
            margin-left: rem(7);
            margin-right: rem(7);
        }
    }

    &_error {
        color: $ku-crimson;
        margin-top: rem(8);
    }
}
