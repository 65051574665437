// fonts
@font-face {
    font-display: swap;
    font-family: 'Brandon Text F';
    font-style: normal;
    font-weight: bold;
    src: url('#{$font-path}BrandonTextF-Bold.woff2') format('woff2'),
        url('#{$font-path}BrandonTextF-Bold.woff') format('woff');
}
  
@font-face {
    font-display: swap;
    font-family: 'Brandon Text F';
    font-style: normal;
    font-weight: 500;
    src: url('#{$font-path}BrandonTextF-Medium.woff2') format('woff2'),
        url('#{$font-path}BrandonTextF-Medium.woff') format('woff');
}
  
@font-face {
    font-display: swap;
    font-family: 'Brandon Text F';
    font-style: normal;
    font-weight: normal;
    src: url('#{$font-path}BrandonTextF-Regular.woff2') format('woff2'),
        url('#{$font-path}BrandonTextF-Regular.woff') format('woff');
}
  
  
@font-face {
    font-display: swap;
    font-family: 'UTM Avo';
    font-style: normal;
    font-weight: bold;
    src: url('#{$font-path}UTMAvoBold.woff2') format('woff2'),
        url('#{$font-path}UTMAvoBold.woff') format('woff');
}
  
@font-face {
    font-display: swap;
    font-family: 'SVN-Brandon Grotesque';
    font-style: italic;
    font-weight: 500;
    src: url('#{$font-path}SVN-BrandonGrotesqueMediumItalic.woff2') format('woff2'),
        url('#{$font-path}SVN-BrandonGrotesqueMediumItalic.woff') format('woff');
}
  
@font-face {
    font-display: swap;
    font-family: 'SVN-Brandon Grotesque';
    font-style: normal;
    font-weight: 500;
    src: url('#{$font-path}SVN-BrandonGrotesqueMedium.woff2') format('woff2'),
        url('#{$font-path}SVN-BrandonGrotesqueMedium.woff') format('woff');
}
  
@font-face {
    font-display: swap;
    font-family: 'SVN-Brandon Grotesque';
    font-style: normal;
    font-weight: normal;
    src: url('#{$font-path}SVN-BrandonGrotesque.woff2') format('woff2'),
        url('#{$font-path}SVN-BrandonGrotesque.woff') format('woff');
}
  
@font-face {
    font-display: swap;
    font-family: 'SVN-Brandon Grotesque';
    font-style: normal;
    font-weight: bold;
    src: url('#{$font-path}SVN-BrandonGrotesqueBold.woff2') format('woff2'),
        url('#{$font-path}SVN-BrandonGrotesqueBold.woff') format('woff');
}
  
@font-face {
    font-display: swap;
    font-family: 'Myriad Pro';
    font-style: normal;
    font-weight: bold;
    src: url('#{$font-path}MyriadPro-Bold.woff2') format('woff2'),
        url('#{$font-path}MyriadPro-Bold.woff') format('woff');
}
