.t-survey {
    $radiusTitle: 12;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    @include desktop-up {
        max-width: 1280px;
    }

    &_image {
        max-width: rem(80);
        margin: 0 auto rem(12);

        @include tablet-down {
            max-width: rem(60);
        }
    }

    &_wrapTitle {
        background: $linear-border;
        border-radius: rem($radiusTitle);
        padding: rem(4);
        margin: 0 auto rem(32);

        @include tablet-down {
            margin: 0 auto rem(16);
            border-radius: rem($radiusTitle / 2);
        }
    }

    &_title {
        background: $linear-seal-brown4;
        border-radius: rem($radiusTitle);
        margin: 0 auto;
        padding: rem(27) rem(16) rem(9);

        @include tablet-down {
            padding: rem(8) rem(32);
            border-radius: rem($radiusTitle / 2);

            .a-heading {
                @include font-base(18, 28);
            }
        }

        &-highlight {
            background: linear-gradient(90deg,
            #f2d05f 0%,
            #ffefb7 50%,
            #f2d05f 99.99%);
        }
    }

    &_rating {
        margin: rem(80) auto rem(144);
        position: relative;
        width: 100%;
        max-width: rem(650);

        @include desktop-down {
            max-width: rem(800);
        }

        @include tablet-down {
            max-width: rem(600);
            margin: rem(40) auto rem(100);
        }

        &_text {
            position: absolute;
            width: 100%;
            height: 100%;
            bottom: calc(-60% - 16px);
            @include adjust-flex(space-between, flex-start);

            @include tablet-down {
                padding: 0 rem(8);
                bottom: calc(-60% - 8px);
            }

            span {
                @include font-base(32, 48);
                color: $dark-midnight-blue;
                font-weight: 700;
                text-align: center;
                @include tablet-down {
                    @include font-base(20, 32);
                }
            }
        }
    }

    &_textarea {
        margin: rem(80) auto rem(40);
        width: 100%;
        max-width: 90%;

        textarea {
            border: 1px solid $pastel-gray;
            border-radius: 8px;
            box-shadow: none;
            font: inherit;
            padding: rem(24);
            resize: none;
            width: 100%;
            outline: none;
            color: $pattrick-blue;
            @include font-base(24, 32);

            &::placeholder {
                color: rgba(109, 110, 113, 0.6);
            }
        }

        @include tablet-down {
            margin: rem(40) auto rem(20);

            textarea {
                @include font-base(16, 24);
            }
        }
    }

    &_subTitle {
        background: linear-gradient(90deg, #f2d05f 0%, #ffefb7 50%, #f2d05f 99.99%);
        border-radius: rem(999);
        margin-top: rem(37);
        padding: rem(16) rem(16) rem(8);

        @include desktop-down {
            margin-top: rem(25);
        }
    }

    &_answers {
        .sm {
            padding: rem(32) 0;

            @include tablet-down {
                padding: rem(16) 0;
            }
        }

        .md {
            padding: rem(56) 0;

            @include tablet-down {
                padding: rem(32) 0;
            }
        }

        h2 {
            font-family: 'SVN-Brandon Grotesque';
        }

        ul.answer {
            list-style: none;
            margin: auto;
            max-width: rem(1070);

            &:not(.flex) {
                @include desktop-down {
                    max-width: rem(920);
                }

                @include tablet-down {
                    max-width: rem(510);
                    padding-top: 0;
                }
            }

            &.flex {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                max-width: rem(1200);

                li {
                    flex: 0 0 33.33333%;
                    margin-top: 0;
                    max-width: 33.33333%;
                    text-align: center;

                    &:nth-child(4),
                    &:nth-child(5) {
                        margin-top: rem(24);

                        @include tablet-down {
                            margin-top: rem(12);
                        }
                    }

                    @include tablet-down {
                        flex: 0 0 30%;
                        max-width: 30%;
                    }

                    .a-radio_content {
                        margin-left: 0;
                        margin-top: rem(12);
                        -webkit-text-size-adjust: 100%;

                        @include tablet-down {
                            margin-top: rem(6);
                        }
                    }
                }
            }

            li {
                margin-top: rem(16);
            }
        }
    }

    &_form {
        &_error {
            color: $red;
            font-size: rem(20);
            font-weight: 700;
            margin: rem(16) 0;
            text-align: center;
            @include tablet-down {
                font-size: rem(16);
            }
        }
    }

    &_button {
        margin: 0 auto;
        max-width: rem(310);
        @include tablet-down {
            max-width: rem(220);
        }

        .a-button {
            font-weight: 700;
            border-radius: rem(12);
            @include font-base(28, 32);

            &_wrapper {
                border-radius: rem(12);
            }
        }
    }

    &_finished {
        margin: auto;
        max-width: rem(944);
        text-align: center;

        .a-button {
            border-radius: rem(12);

            &_wrapper {
                border-radius: rem(12);
            }
        }
        @include tablet-down {
            max-width: rem(720);
        }

        img {
            @include tablet-down {
                margin: auto;
                max-width: rem(120);
            }
        }

        .a-heading {
            margin-bottom: rem(24);
            margin-top: rem(36);

            @include tablet-down {
                margin-bottom: rem(18);
                margin-top: rem(24);
                line-height: rem(34);
            }
        }

        &_button {
            align-items: center;
            display: flex;
            justify-content: space-around;
            margin: rem(24) auto 0;
            max-width: rem(353);

            @include mobile-down {
                max-width: rem(320);
            }

            .a-button {
                border-radius: rem(12);
    
                &_wrapper {
                    border-radius: rem(12);
                }
            }
        }
    }
}

// .o-popup {
//   max-width: rem(818);

//   &::after {
//     background-size: 100% 130px;
//   }
// }
