html,
body {
    font-family: $font-svn-brandon;
    font-size: 16px;
    overflow-x: hidden;
}

* {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent; //issue click in mobile flash background highligh
}

.wrapper {
    overflow: hidden;
}

button {
    &:focus {
        outline: none;
    }
}

p {
    margin-bottom: 0;
    margin-top: 0;
    -webkit-text-size-adjust: 100%;
}

a {
    text-decoration: none;
}

[role='button'],
button {
    cursor: pointer;

    &:focus {
        outline: none;
    }
}

input {
    word-break: normal;
}

ul,
ol {
    margin: 0;
    padding: 0;
}

.hidden-animation {
    visibility: hidden;
}

.overflow-hidden {
    height: 100vh;
}

.u-mt {
    &-24 {
        margin-top: rem(24);
    }

    &-16 {
        margin-top: rem(16);
    }
}

.tablet-hide {
    @include tablet-down {
        display: none;
    }
}

.tablet-show {
    @include tablet-up {
        display: none;
    }
}
