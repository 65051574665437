.t-loginEvent {
    &_video {
        height: 100vh;
        width: 100%;

        @include desktop-up {
            min-height: rem(680);
        }

        video {
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }
    }

    &_button {
        bottom: 10%;
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
        width: rem(200);

        @include break-min($standard-viewport) {
            width: 220 * $w;

            .a-button {
                font-size: 20 * $w;
                height: 55 * $w;
                line-height: 24 * $w;
            }
        }
    }

    &_input {
        .a-text {
            font-size: rem(20) !important;
            line-height: rem(32) !important;
            @include mobile-down {
                font-size: rem(16) !important;
                line-height: rem(24) !important;
            }
        }

        .a-input_errorMessage {
            span {
                @include font-base(20,32);
                @include mobile-down {
                    @include font-base(16,24);
                }
            }
        }

        &:not(:first-child) {
            margin-top: rem(16);

            @include tablet-down {
                margin-top: rem(12);
            }
        }
    }

    &_submit {
        margin: rem(24) auto 0;
        max-width: rem(270);

        .a-button {
            border-radius: 12px;
            border: 2px solid var(--dkngaystroke, #ecbc79);
            background: var(--DKNGAY, linear-gradient(87deg, #ecbc79 0.57%, #bc8b42 7.36%, #edd79d 25.89%, #fff3bf 51%, #ecd69c 75.3%, #bc8b42 88.26%, #ecbc79 98.19%));

            &_wrapper {
                background: none;
            }
        }

        @include tablet-down {
            margin-top: rem(16);

            button {
                font-size: rem(14);
                height: 2.5rem;
            }
        }
    }

    &_wrapRegister {
        @include adjust-flex(); }

    &_register {
        margin-left: rem(4);
        cursor: pointer;

        .a-text {
            @include text-background($linear-seal-brown); }
    }

    &_forgotPassword {
        margin-top: rem(17);

        .a-text {
            @include text-background($linear-seal-brown); }

        cursor: pointer;

        @include tablet-down {
            font-size: rem(14);
        }
    }

    &_form {
        max-width: rem(400);
        margin: 0 auto;

        &_title {
            text-align: center;

            span {
                display: block;
                margin-top: rem(10);
            }
        }

        &_notice {
            color: $dark-cerulean;
            font-size: rem(24);
            font-weight: 700;
            margin-bottom: rem(20);
            text-align: center;
        }

        .a-input_label .a-text {
            color: $pattrick-blue;
            text-transform: uppercase;
            @include font-base(14, 22);
        }
    }
}

.t-login {
    position: relative;

    &_background {
        .a-image {
            @include tablet-down {
                @include aspect-ratio(383, 851);
            }
        }
    }

    &_text {
        left: 8%;
        max-width: rem(528);
        position: absolute;
        top: 20%;
        width: 100%;

        @include break-min($standard-viewport) {
            max-width: 528 * $w;
        }

        @include desktop-down {
            max-width: rem(384);
        }

        @include tablet-down {
            left: 50%;
            max-width: rem(350);
            top: 50%;
            transform: translateX(-50%);
        }

        img {
            width: 100%;
        }
    }

    &_context {
        margin-top: rem(48);

        &_button {
            margin: auto;
            max-width: rem(270);

            @include break-min($standard-viewport) {
                max-width: 270 * $w;

                .a-button {
                    font-size: 24 * $w;
                    height: 56 * $w;
                    line-height: 32 * $w;
                }
            }

            @include mobile-down {
                max-width: rem(190);

            }

            .a-button {
                border-radius: 12px;
                border: 2px solid var(--dkngaystroke, #ecbc79);
                background: var(--DKNGAY, linear-gradient(87deg, #ecbc79 0.57%, #bc8b42 7.36%, #edd79d 25.89%, #fff3bf 51%, #ecd69c 75.3%, #bc8b42 88.26%, #ecbc79 98.19%));

                &_wrapper {
                    background: none;
                }
            }
        }

        &_register {
            text-align: center;
            margin-top: rem(16);

            div {
                margin-left: rem(7);
                cursor: pointer;
            }

            p {
                display: inline-flex;
            }

            @include mobile-down {
                margin-top: rem(8);

                p {
                    @include font-base(14, 22); }
            }
        }

        @include mobile-down {
            margin-top: rem(16);
        }
    }

}

.t-resetPassword {
    &_button {
        margin: rem(24) auto;
        max-width: rem(270);

        .a-button {
            border-radius: 12px;
            border: 2px solid var(--dkngaystroke, #ecbc79);
            background: var(--DKNGAY, linear-gradient(87deg, #ecbc79 0.57%, #bc8b42 7.36%, #edd79d 25.89%, #fff3bf 51%, #ecd69c 75.3%, #bc8b42 88.26%, #ecbc79 98.19%));

            &_wrapper {
                background: none;
            }
        }
    }
}
